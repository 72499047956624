body {
    margin: 0;
    font-family: 'New Tegomin', serif;
font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


:root{
    font-size: 16px;
}

body::-webkit-scrollbar {
    width: 0.3rem;
}

body::-webkit-scrollbar-track {
    
}

body::-webkit-scrollbar-thumb {

}

.main {
    margin-left: 5rem;
}


.fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
    }
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover 
{
     color:unset !important;
     text-decoration:none !important; 
     cursor:pointer;  
}




.auroaUIContainer{
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -5;
}
.auroaUIContainer div{
    position: absolute;
    border-radius: 50%;
    animation: gradient 4s infinite;
}
@keyframes gradient{
    70%{
        transform: scale(1.2) translate(50px);
    }
}
.auroaUIContainer div:nth-child(1){
    height: 90%;
    width: 80%;
    background-color: #1d1d1d;
    left: -20%;
    top: -10%;
}
.auroaUIContainer div:nth-child(2){
    height: 80%;
    width: 70%;
    background-color: #1a1a1a;
    top: -12%;
    right: -12%;
    animation-delay: 2s;
}
.auroaUIContainer div:nth-child(3){
    height: 65%;
    width: 80%;
    background-color: #1f1e1e;
    bottom: -15%;
    right: -20%;
    animation-delay: 0.3s;
}
.auroaUIContainer div:nth-child(4){
    height: 50%;
    width: 75%;
    background-color: #1b1b1b;
    bottom: -10%;
    left: -18%;
    animation-delay: 1.2s;
}
.auroaUIContainer:after{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(30px);
    /**
    **/
}

.glass {
    background: rgba( 0, 0, 0, 0.3 );
    box-shadow: 0 8px 32px 0 rgba(135, 31, 31, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.glassPanel {
    background: #b71c1ca1;
    box-shadow: 0 8px 32px 0 rgba(135, 31, 31, 0.137);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
}

.glass-white {
    background: rgba(255, 255, 255, 0.329);
    box-shadow: 0 8px 32px 0 rgba(135, 31, 31, 0.37);
    backdrop-filter: blur( 5.0px );
    -webkit-backdrop-filter: blur( 5.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 4px 12px;
}